import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PersonalDataItemComponent } from './personal-data-item/personal-data-item.component';
import { CvService } from './cv.service';
import { HttpClientModule } from '@angular/common/http';
import { BarDataItemComponent } from './bar-data-item/bar-data-item.component';
import { HobbieItemComponent } from './hobbie-item/hobbie-item.component';
import { TimelineItemComponent } from './timeline-item/timeline-item.component';
import { CircleBarComponent } from './circle-bar/circle-bar.component';
import { CardUrlComponent } from './card-url/card-url.component';

@NgModule({
  declarations: [
    AppComponent,
    PersonalDataItemComponent,
    BarDataItemComponent,
    HobbieItemComponent,
    TimelineItemComponent,
    CircleBarComponent,
    CardUrlComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [CvService],
  bootstrap: [AppComponent]
})
export class AppModule { }
