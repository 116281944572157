import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-bar-data-item',
  templateUrl: './bar-data-item.component.html',
  styleUrls: ['./bar-data-item.component.scss']
})
export class BarDataItemComponent implements OnInit {

  @Input()
  public barItem = null;

  constructor() { }

  ngOnInit() {
  }

}
