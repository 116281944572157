import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-personal-data-item',
  templateUrl: './personal-data-item.component.html',
  styleUrls: ['./personal-data-item.component.scss']
})
export class PersonalDataItemComponent implements OnInit {

  @Input()
  public personalItem = null;

  constructor() {

  }

  ngOnInit() {
  }

  isNotNull():boolean{
    return this.personalItem != null;
  }

  hasLink():boolean{
    return this.personalItem["link"] != null;
  }



}
