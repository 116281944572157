import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as M from "materialize-css/dist/js/materialize";
import { CvService } from './cv.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public cv = null;

  @ViewChild('dropdownLan',{ static: true }) dropdownLan: any;

  private lang = "es";


  constructor(private _cvService: CvService, private _route: ActivatedRoute){

  }

  ngOnInit(){
    this._route.queryParams.subscribe(params => {
      let param = params['lang'];
      if(param){
        this.lang = param;
      }
      this._cvService.getCvData(this.lang).subscribe(data =>{
          this.cv = data
        }
      );
    });
  }

  ngAfterViewInit() {
    M.Dropdown.init(this.dropdownLan.nativeElement);
  }

  cvIsNotNull():boolean{
    return this.cv != null;
  }

  get isES(){
    return this.lang == "es";
  }

  get cvIsNull(){
    return this.cv != null;
  }

  get emailItem(){
    return this.cv.personal_items.filter(elem=> elem.type=='email')[0];
  }

  get phoneItem(){
    return this.cv.personal_items.filter(elem=> elem.type=='phone')[0];
  }

}
