import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-circle-bar',
  templateUrl: './circle-bar.component.html',
  styleUrls: ['./circle-bar.component.scss']
})
export class CircleBarComponent implements OnInit {

  @Input()
  public circleBarItem = null;

  constructor() { }

  ngOnInit() {
  }

  getPercentageClass(){
    return 'p'+this.circleBarItem.percentage;
  }

}
