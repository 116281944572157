import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-hobbie-item',
  templateUrl: './hobbie-item.component.html',
  styleUrls: ['./hobbie-item.component.scss']
})
export class HobbieItemComponent implements OnInit {

  @Input()
  public hobbieItem = null;

  constructor() { }

  ngOnInit() {
  }

}
